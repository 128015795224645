<template>
<div>
	<CRow>
		<CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="9" class="text-left"><h4>DTO Operator Image Upload</h4></CCol>
             <CCol col="3" class="text-right">
                    &nbsp;
             </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>

           <CForm>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Country</label>
                  </b-col>
                  <b-col sm="9">
                     <b-form-select v-model="country" :disabled="!isDisabledcurr"  :options="optCurrency" @change="onCountryChange"></b-form-select>
                   </b-col>
                  </b-col>
              </b-row>
							<br/>
							<b-row>
                  <b-col sm="3">
                     <label for="input-small" >Operator</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select v-model="operatorId" :options="optWallet" @change="onOperatorChange"></b-form-select>
                   </b-col>
                  </b-col>
              </b-row><br/>
							<b-row>
								<b-col sm="3">
									 <label for="input-small" >Resource </label>
								</b-col>
								<b-col sm="9">
									 <input type="file" :disabled="!isDisabledfile" ref="fileupload" accept="image/x-png,image/gif,image/jpeg" @change="onFileChange" />
								</b-col>
							</b-row>
							<br />
							<b-row v-if="previewURL && previewURL != ''">
								<b-col sm="3">
									&nbsp;
								</b-col>
								<b-col sm="9">
									<img v-if="previewURL && previewURL != ''" :src="previewURL" style="max-width: 100%;width: auto;" />
								</b-col>
							</b-row>
							<br v-if="previewURL && previewURL != ''"/>


              <b-row>
                  <b-col sm="3"></b-col>
                  <b-col sm="9">
                     <b-button-group>
                     	<CButton v-on:click="actionSubmit()" color="primary" :disabled="!isDisabledtrType">Submit Upload</CButton>
                     </b-button-group>
                  </b-col>
                  </b-col>
              </b-row><br/>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>

              <CRow>
                <CCol col="12" class="text-left">
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
              </CRow>

            </CForm>

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>



<script>
 import AuthService from '@/api/AuthService.js';
 import VueTimepicker from 'vue2-timepicker';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import readXlsxFile from 'read-excel-file';
 import moment from 'moment';

  export default {
    name: 'dtodetail',
    components: {
      VueTypeaheadBootstrap,
      VueTimepicker
    },
    data() {
			var date = new Date();
			var exprdate = new Date(date);
			exprdate.setFullYear(date.getFullYear() + 5);
      return {
          trRef:'',
          otp:'',
					checked: false,
					hiddenChecked : true,
          seenOTP:false,
          sent:false,
          seenfile:false,
          cbbfile:false,
          isDisabledSlc: true,
          isDisabledfile:true,
          isDisabledcurr:true,
          isDisabledamount:true,
          isDisabledtrType:true,
          seenBtnDwld:false,
          optCurrency: [],
          optNumber:[],
          optWallet:[],
          optFeatures:[],
          phoneNum:'',
          baseItems:[],
          optTrType:[],
          reportList:[],
          report:[],
          successList:[],
          failedList:[],
          userId:'',
          displayName:'',
          operatorId:'',
          previewURL:'',
          country:'',
          amount:'',
          reason:'',
          feature:'',
          items:[],
          spinner:false,
          filter: null,
          seenOther: false,
          seen:false,
          msg :'',
          color:''
      };
    },
    created () {
        this.getConfigList();
    },
    methods: {

      hidenSeek(e){

        if(this.checked){
           	this.hiddenChecked = false;
        } else {
						this.hiddenChecked = true;
				}

      },

      async actionSubmit(){
            this.spinner = true;
            let params = {}; var validate = [];
            params["country"] = this.country;
            params["operator"] = this.operatorId;
            params["resource"] = this.cbbfile;

            for (var key in params) {
              	if(!params[key]) {
									validate.push(false);
								} else {
									validate.push(true);
								}
            }

            //console.log(params);
            if(!validate.includes(false)){
								let data = new FormData();
						    data.append('country', this.country);
						    data.append('operatorName', this.operatorId);
						    data.append('resource', this.cbbfile);

								console.log('submit upload dto image');
								AuthService.dtoUploadImage(data).then(resp => {
										//console.log(resp);
										if(resp.statusCode == "S0000") {
												this.msg   = 'Successfully upload to DTOne : ' + resp.message;
												this.color = 'success';
												this.seen  = true;
												this.actionReset();
							      } else {
												this.msg   = 'something problem with your file';
												this.color = 'warning';
												this.seen  = true;
										}
										this.spinner = false;
										setTimeout( () =>  this.seen=false , 5000);

								}, error => {
										this.msg   = 'something problem with within this process';
										this.color = 'warning';
										this.seen  = true;
										this.spinner = false;
										setTimeout( () =>  this.seen=false , 5000);
								});

						} else {
								this.spinner = false;
								this.msg   = 'Input Not Complete, All Data Required!';
	              this.color = 'warning'; this.seen  = true; this.spinner = false;
	              setTimeout( () =>  this.seen=false , 5000);
            }

      },

      actionReset(){
          this.optWallet = [];
          this.operatorId = "";
          this.country = "";
          this.cbbfile= null;
					this.$refs.fileupload.value=null;
					this.previewURL='';
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      onFileChange(event) {
        	this.cbbfile = event.target.files ? event.target.files[0] : null;
					if (this.cbbfile) {
						 this.previewURL = URL.createObjectURL(this.cbbfile);
					}
      },

      onOperatorChange(event) {
					if(this.baseItems.hasOwnProperty(event) && this.baseItems[event] != '') {
							//console.log(this.baseItems[event]);
							AuthService.getResourceDTOByKey(this.baseItems[event]).then(resp => {   //console.log(resp);
									try {

											this.previewURL = ('data:image/jpeg;base64,' + btoa(
												 new Uint8Array(resp).reduce((data, byte) => data +
												 String.fromCharCode(byte), '')
											));

									} catch (err) {

											console.log(err)

									}


							}, error => {
									console.log(error);
							});

					} else {
						this.previewURL = '';
					}
      },

    	onCountryChange(event) {
					this.optWallet = [];
					AuthService.getDTOOperatorByCountry(event).then(resp => {   //console.log(resp);
							var operators = [];
							var arrOperator = (resp.hasOwnProperty('payload')) ? resp.payload : [];
							var operatorName = '';
							if(arrOperator.length > 0){
								this.isDisabledtrType = true;
								var dis = this;
								var optionItems = [];
								arrOperator.sort((a,b) => (a.operatorName > b.operatorName) ? 1 : ((b.operatorName > a.operatorName) ? -1 : 0));
								for (var key in arrOperator) {
										if (key == 0) {
												operatorName = arrOperator[key].operatorName;
										}
										optionItems[''+arrOperator[key].operatorName+''] = (arrOperator[key].hasOwnProperty('operatorImagePath')) ? arrOperator[key].operatorImagePath : '';
										operators.push({ 'value' : arrOperator[key].operatorName, 'text' : arrOperator[key].operatorName });
								}
								//console.log(optionItems);
								this.baseItems = optionItems;
							} else {
								this.isDisabledtrType = false;
							}
							this.operatorId = operatorName;
							this.onOperatorChange(operatorName);
							this.optWallet = operators;
					}, error => {
							console.log(error);
					});
      },

			sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
      },

      getConfigList: function() {
          AuthService.getDTOCountry().then(resp => {   //console.log(resp);
							var currSrc=[]; var temp = [];
							var arrCountry = resp.payload;
							Object.entries(arrCountry).sort().forEach(entry => {
									const [key, value] = entry;
									currSrc.push({ value: key , text: value });
							});
							currSrc.sort();
							this.optCurrency = currSrc;
					}, error => {
							console.log(error);
					});
      },

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
<style>
/* .list-group shadow vbt-autcomplete-list {  background: black; } */
[role~=listbox]{ background: black; }
</style>
